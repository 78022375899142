import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

export function setTbaTime(date: string | Date) {
  const dayjsDate = dayjs(date).tz('America/Los_Angeles').hour(23).minute(59).second(0);
  // TBA time is determined by 23:59 time PST, we determine TBA date by putting the input date in PST
  // So for timezones east of PST we need to add date
  // Example: 11/2/22 02:59 EST will be converted to 11/1 using getTBADate function below
  // so by adding the one day it will properly display as 11/2 when using getLocalizedDate across the site
  return isEastOfPST() ? dayjsDate.add(1, 'day').toDate() : dayjsDate.toDate();
}

export function isTbaTime(date: string | Date) {
  const pstDate = dayjs(date).tz('America/Los_Angeles');
  return (pstDate.hour() == 23 && pstDate.minute() == 59) || (pstDate.hour() == 0 && pstDate.minute() == 0);
}

export function setTimeOnly(oldDate: string | Date | undefined, newDate: string | Date) {
  // If date has time TBA east of PST then the date is a day head local time
  // so when changing the time from TBA to something set we need to subtract a day
  // EX: 11/2/22 02:59 EST is 11/1 so if time is set to 12:00 we need to change
  // date to 11/1/22 12:00 EST
  if (!oldDate || !isTbaTime(oldDate)) return newDate;
  return isEastOfPST() ? dayjs(newDate).subtract(1, 'day').toDate() : newDate;
}

export function setDateOnly(oldDate: string | Date | undefined, newDate: Date | undefined) {
  if (!oldDate || !newDate) return newDate;
  const oldDayJs = dayjs(oldDate);
  const updatedDate = dayjs(newDate).hour(oldDayJs.get('hour')).minute(oldDayJs.get('minute')).second(oldDayJs.get('second'));
  if (isTbaTime(oldDate) && isEastOfPST()) {
    return updatedDate.add(1, 'day').toString();
  }
  return updatedDate.toString();
}

function isEastOfPST() {
  const pstDate = dayjs(new Date()).tz('America/Los_Angeles');
  return +pstDate.format('ZZ') < +dayjs(new Date()).format('ZZ');
}

// in different timezones TBA date of 11:59pm can actually show wrong date since its 1:59 AM ET for example. hacky fix
export function getTbaDate(date: string | Date) {
  const tbaDate = dayjs(date).hour(0).minute(0).second(0).tz('America/Los_Angeles').format('YYYY-MM-DDTHH:mm:ss') + dayjs(date).format('Z');
  return new Date(tbaDate);
}

export function localizeDate(date: string | Date, dateFormat: string) {
  if (isTbaTime(date) && /A+|a+|H+|h+|k+|m+|s+|S+|z+|Z+/.test(dateFormat)) {
    return 'TBA';
  } else if (isTbaTime(date)) {
    return dayjs(getTbaDate(date)).format(dateFormat);
  } else {
    return dayjs(date).format(dateFormat);
  }
}

interface LocalizedDateProps {
  className?: string;
  date: string;
  format: string;
}

export default function LocalizedDate({ className, date, format: dateFormat }: LocalizedDateProps) {
  const [dateString, setDateString] = useState<string>();

  useEffect(() => {
    setDateString(localizeDate(date, dateFormat));
  }, [date, dateFormat]);

  return (
    <span className={className} dangerouslySetInnerHTML={!dateString ? { __html: '&nbsp;'.repeat(dateFormat.length) } : undefined}>
      {dateString}
    </span>
  );
}
