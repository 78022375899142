// TODO make this a pack & use append_pack_tag

import * as Sentry from '@sentry/react';
import { ENVIRONMENT, PRODUCTION, DEVELOPMENT } from 'constants.js.erb';

const SENTRY_IGNORE_ERRORS_DOMAINS = ['https://powerad.ai/'];
const SENTRY_IGNORE_ERROR_MESSAGES = [
  `reading 'mip'`, // powerad sometimes throws:  Cannot read properties of undefined (reading 'mip')
  `reading 'iOnSelector'`,
  'paGlobal',
  'this.vids[this.is.vid]',
  `reading 'clsBtn'`,
  `reading 'pause'`,
  `evaluating 'frameDoc.open'`,
  `reading 'tracker'`,
  `reading 'refresh'`,
  'getAdPos',
  `evaluating 'this.props.adSkippableState'`,
  `evaluating 'this._excoPlayer.on'`,
  `setting 'closed'`,
  'getBoundingClientRect',
  'The play() request was interrupted by a call to pause()',
  'NotSupportedError: The operation is not supported',
  'AbortError: The operation was aborted'
];

export default function sentry() {
  Sentry.init({
    dsn: 'https://e2bc81020c1886413ff315fb1705284e@o4506673361387520.ingest.sentry.io/4506714037026816',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    allowUrls: [/scorebooklive.com/],
    denyUrls: SENTRY_IGNORE_ERRORS_DOMAINS,
    environment: ENVIRONMENT.toLowerCase(),
    enabled: ENVIRONMENT != DEVELOPMENT,
    beforeSend(event, hint) {
      const fileName = event?.exception?.values?.[0]?.stacktrace?.frames?.[0].filename;

      if (SENTRY_IGNORE_ERRORS_DOMAINS.some(domain => fileName?.includes(domain))) {
        return null;
      }

      const errorText = event.exception?.values?.[0].value;
      if (SENTRY_IGNORE_ERROR_MESSAGES.some(text => errorText?.includes(text))) {
        return null;
      }

      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: ENVIRONMENT == PRODUCTION ? 0.01 : 0.5,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/scorebooklive.com/],

    // Capture Replay for 0% of all sessions, plus for 5% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: ENVIRONMENT == PRODUCTION ? 0.05 : 0
  });
}
