import { ReactNode } from 'react';
import styles from './styles/FanAppPage.module.scss';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import MinuteMediaAd from '../ads/MinuteMediaAd';
import StnVideoAd from '../ads/StnVideoAd';

export default function FanAppPage({ children, subNav }: { children: ReactNode; subNav?: ReactNode }) {
  const [{ disableAds }] = useApplicationContainer();

  return (
    <>
      {subNav && <header className={styles.FixedHeader}>{subNav}</header>}
      {!disableAds && <MinuteMediaAd placement="TOP" />}
      <StnVideoAd placement="TOP" />
      <main className={styles.Main}>{children}</main>
      <MinuteMediaAd placement="BOTTOM" />
    </>
  );
}
