import { ComponentType } from 'react';
import ReactRailsUJS from 'react_ujs';
import { withApplicationContainerMounter } from 'containers/ApplicationContainer';

export function pack(component: ComponentType<any>) {
  if (typeof window === 'undefined') return component;

  ReactRailsUJS.getConstructor = function () {
    return withApplicationContainerMounter(component);
  };

  return component;
}
