import { CSSProperties } from 'react';
import { IconType } from '.';

interface PhotoSquareProps extends IconType {
  style?: CSSProperties;
}

export default function PhotoIcon({ className = '', color = '#fff', height = 60, style, width = 60 }: PhotoSquareProps) {
  // TODO:  if needed, use props to customize display in the future
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.305" height="14.237" viewBox="0 0 18.305 14.237">
      <path
        id="images-regular"
        d="M5.085,33.525H16.271a.51.51,0,0,1,.508.508v7.118a.51.51,0,0,1-.508.508H15.6l-3.264-4.992a.762.762,0,0,0-1.278,0L9.4,39.2,9,38.667a.766.766,0,0,0-1.233,0L5.58,41.661h-.5a.51.51,0,0,1-.508-.508V34.034A.51.51,0,0,1,5.085,33.525Zm-2.034.508v7.118a2.036,2.036,0,0,0,2.034,2.034H16.271A2.036,2.036,0,0,0,18.3,41.152V34.034A2.036,2.036,0,0,0,16.271,32H5.085A2.036,2.036,0,0,0,3.051,34.034ZM1.525,34.8A.763.763,0,1,0,0,34.8v7.118a4.322,4.322,0,0,0,4.322,4.322H14.491a.763.763,0,0,0,0-1.525H4.322a2.8,2.8,0,0,1-2.8-2.8Zm6.61.763a1.017,1.017,0,1,0-1.017,1.017A1.017,1.017,0,0,0,8.135,35.559Z"
        transform="translate(0 -32)"
        fill="#fff"
      />
    </svg>
  );
}
