import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './styles/SectionHeader.module.scss';

export enum SectionHeaderTag {
  H1 = 'h1',
  H2 = 'h2'
}

export enum SectionHeaderSize {
  EXTRA_SMALL = 'ExtraSmall',
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  LARGE_MEDIUM = 'LargeMedium'
}

export interface SectionHeaderProps {
  center?: ReactNode;
  right?: ReactNode;
  children?: ReactNode;
  panel?: boolean;
  size?: SectionHeaderSize;
  tag?: SectionHeaderTag;
  flush?: boolean;
}

export default function SectionHeader({ center, right, children, size = SectionHeaderSize.LARGE, tag, flush }: SectionHeaderProps) {
  const TitleTag = tag || 'div';

  const title = <TitleTag className={cn(styles.TitleText, styles[`TitleText${size}`], { [styles.Flush]: flush })}>{children}</TitleTag>;

  if (!center && !right) {
    return title;
  }

  return (
    <div className={cn(styles.Header, { [styles.Flush]: flush, [styles.ThreeColumns]: center && right })}>
      <div className={styles.Title}>{title}</div>
      {center && <div className={styles.Center}>{center}</div>}
      {right && <div className={styles.Right}>{right}</div>}
    </div>
  );
}
