import { ReactNode } from 'react';
import styles from './styles/ContentSection.module.scss';
import Button, { ButtonProps, ButtonSize, ButtonVariant } from './Button';
import cn from 'classnames';
import SectionHeader, { SectionHeaderProps, SectionHeaderSize } from './SectionHeader';
import TextLink, { TextLinkProps, TextLinkSize } from './TextLink';
import { ScreenSize } from './ScreenSizes';

export function ContentSectionHeader({ size, ...props }: SectionHeaderProps) {
  return <SectionHeader size={size || SectionHeaderSize.LARGE_MEDIUM} {...props} />;
}

export function ContentSectionHeaderLink({ size, ...props }: TextLinkProps) {
  return <TextLink size={size || TextLinkSize.MEDIUM} {...props} />;
}

export function ContentSectionFooterButton({ className, ...props }: ButtonProps) {
  return <Button variant={ButtonVariant.GRAY} className={cn(styles.FooterButton, className)} size={ButtonSize.LARGE} {...props} />;
}

export function ContentSectionFlushContent({
  children,
  screenSize = ScreenSize.SMALL,
  className
}: {
  children: ReactNode;
  screenSize?: Exclude<ScreenSize, ScreenSize.LARGE | ScreenSize.EXTRA_LARGE>;
  className?: string;
}) {
  return <div className={cn(styles[`Flush${screenSize}`], className)}>{children}</div>;
}

export interface ContentSectionProps {
  children: ReactNode;
  className?: string;
  order?: number;
}

export default function ContentSection({ children, className, order }: ContentSectionProps) {
  return (
    <div className={cn(styles.Section, className)} style={{ order }}>
      {children}
    </div>
  );
}
