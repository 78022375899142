import { USER_CACHE_KEY, authenticateUser } from 'stores/userStore';
import 'analytics/gtm';
import sentry from 'lib/sentry';
import { ComponentType } from 'react';
import { pack } from './pack';
import '@/styles/global.scss';
import { sessionCache } from 'helpers/cache';

declare global {
  interface Window {
    triggerScreenFocus: (isFocused: boolean) => void;
  }
}

if (typeof window !== 'undefined') {
  window.triggerScreenFocus = function (isFocused) {
    const event = new CustomEvent('screenfocus', { detail: isFocused });
    window.dispatchEvent(event);
  };
}

export function entryPoint(component: ComponentType<any>) {
  if (typeof window === 'undefined') return component;

  sentry();

  if (!sessionCache.get(USER_CACHE_KEY)) authenticateUser();

  return pack(component);
}
