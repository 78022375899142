import Chevron from 'icons/Chevron';
import Clickable, { ClickableProps } from './Clickable';
import styles from './styles/TextLink.module.scss';
import cn from 'classnames';

export enum TextLinkSize {
  INHERIT = 'Inherit',
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large'
}

export type TextLinkProps = ClickableProps & {
  size?: TextLinkSize;
  forward?: boolean;
};

export default function TextLink({ size = TextLinkSize.MEDIUM, className, children, forward, ...props }: TextLinkProps) {
  return (
    <Clickable className={cn(styles.TextLink, size != TextLinkSize.INHERIT && styles[size], className)} {...props}>
      {children && (
        <>
          {children}
          {forward && <Chevron height={12} width={6} color="#2776ea" />}
        </>
      )}
    </Clickable>
  );
}
