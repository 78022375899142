import { onEvent, GOOGLE_AD_VIEW, LOGIN, SPONSOR_CLICK, SPONSOR_VIEW, SCOREBOARD_NEXT_PAGE } from './events';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export function gtmEvent<T>(eventName: string, eventParams?: T) {
  const event = {
    event: eventName,
    ...eventParams
  };

  if (window.dataLayer) {
    window.dataLayer.push(event);
  } else {
    console.log('[DATALAYER EVENT]', event);
  }
}

/**** EVENTS *****/

onEvent(GOOGLE_AD_VIEW, ({ placement }) => {
  gtmEvent('google_ad_view', {
    event_placement: placement
  });
});

onEvent(LOGIN, () => {
  gtmEvent('login');
});

onEvent(SCOREBOARD_NEXT_PAGE, () => {
  gtmEvent('scoreboard_next_page');
});

onEvent(SPONSOR_CLICK, ({ sponsor, placement }) => {
  gtmEvent('sponsor_ad_click', {
    event_type: sponsor.slug,
    event_placement: placement
  });
});

onEvent(SPONSOR_VIEW, ({ sponsor, placement }) => {
  gtmEvent('sponsor_ad_view', {
    event_type: sponsor.slug,
    event_placement: placement
  });
});
