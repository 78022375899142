import { API_BASE_URL } from 'constants.js.erb';
import styles from './styles/ApiDomainBadge.module.scss';
import cn from 'classnames';

export default function ApiDomainBadge() {
  return (
    <div
      className={cn(styles.Container, {
        [styles.Staging]: API_BASE_URL.match(/staging/),
        [styles.Development]: API_BASE_URL.match(/3000/)
      })}
    >
      {API_BASE_URL}
    </div>
  );
}
