import styles from './styles/PageSection.module.scss';
import { ReactNode } from 'react';
import cn from 'classnames';
import { ScreenSize } from './ScreenSizes';

export default function PageSection({
  children,
  className,
  fullWidth,
  flush
}: {
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
  flush?: Exclude<ScreenSize, ScreenSize.LARGE | ScreenSize.EXTRA_LARGE>;
}) {
  return (
    <div className={cn(styles.PageSection, flush && styles[`Flush${flush}`], { [styles.FullWidth]: fullWidth }, className)}>{children}</div>
  );
}
