import { ReactNode } from 'react';
import styles from './styles/PageLayout.module.scss';
import cn from 'classnames';
import PageSection from './PageSection';
import { ScreenSize } from './ScreenSizes';

export enum PageLayoutVariant {
  CONTENT = 'Content',
  PANELS = 'Panels'
}

export default function PageLayout({
  children,
  header,
  sidebar,
  variant = PageLayoutVariant.CONTENT
}: {
  children: ReactNode;
  header?: ReactNode;
  sidebar?: ReactNode;
  variant?: PageLayoutVariant;
}) {
  return (
    <PageSection flush={ScreenSize.SMALL} className={cn(styles.PageLayout, styles[variant], { [styles.FullWidth]: !sidebar })}>
      {header && <div className={styles.Header}>{header}</div>}
      <div className={styles.Main}>{children}</div>
      {sidebar && <div className={styles.Sidebar}>{sidebar}</div>}
    </PageSection>
  );
}

export function PageLayoutModule({ children, order }: { children: ReactNode; order?: number }) {
  return (
    <div className={styles.Module} style={{ order }}>
      {children}
    </div>
  );
}
