import { ReactNode } from 'react';
import FanAppPage from './fanApp/FanAppPage';
import WebPage from './web/WebPage';
import { ENVIRONMENT } from 'constants.js.erb';
import ApiDomainBadge from './ApiDomainBadge';
import { useApplicationContainer } from 'containers/ApplicationContainer';

export default function Page(props: { children: ReactNode; subNav?: ReactNode }) {
  const [{ isFanApp }] = useApplicationContainer();

  const PageComponent = isFanApp ? FanAppPage : WebPage;

  return (
    <>
      <PageComponent {...props} />
      {ENVIRONMENT === 'DEVELOPMENT' && <ApiDomainBadge />}
    </>
  );
}
