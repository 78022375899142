import AdPlaceholder from './AdPlaceholder';
import cn from 'classnames';
import styles from './styles/MinuteMediaAd.module.scss';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import { MINUTE_MEDIA_ADS_ENABLED } from 'constants.js.erb';
import useScreenSize from 'hooks/useScreenSize';

const minuteMediaPlacements = {
  TOP: 'div-gpt-ad-1552218170642-0',
  BOTTOM: 'div-sticky-bottom',
  SIDEBAR: 'div-sideBar1',
  SIDEBAR_2: 'div-sideBar2',
  IN_FEED: 'below-top-section',
  IN_FEED_2: 'below-second-section'
};

function isSidebar(placement: MinuteMediaPlacementType) {
  return placement === 'SIDEBAR' || placement === 'SIDEBAR_2';
}

export type MinuteMediaPlacementType = keyof typeof minuteMediaPlacements;

export default function MinuteMediaAd({ placement, className }: { placement: MinuteMediaPlacementType; className?: string }) {
  const [{ disableAds }] = useApplicationContainer();
  const { isSmallScreen } = useScreenSize();

  if (disableAds || (isSmallScreen && isSidebar(placement))) return null;

  const ad = (
    <AdPlaceholder
      className={cn(styles.Ad, styles[placement], { [styles.Placeholder]: !MINUTE_MEDIA_ADS_ENABLED }, className)}
      id={minuteMediaPlacements[placement]}
      placeholder={!MINUTE_MEDIA_ADS_ENABLED}
    >
      MinuteMediaAd ({placement})
    </AdPlaceholder>
  );

  // wrap sidebar ads in div due to auto padding / styling in PageLayout
  if (isSidebar(placement)) {
    return <div>{ad}</div>;
  }

  return ad;
}
